
.selectForm{
    width: 100%;
    height: 56px;
    border: 1px solid #bdbcbc;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    padding: 16.5px 14px;
    color: currentColor;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
}
.selectForm:hover{
    border-color: black;
}
.pass{
   /* display: none; */
   /* visibility: hidden; */
    display: none !important;
}
.cursorPointer{
    height:30px;
    line-height:30px;
    background:#f4f4f4;
    font-size: 18px;
}