.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.logo-container {
  /* height: 23px; */
  border-radius: 6px;
    height: 40px;
    background: white;
    width: 40px;
    padding: 4px;
}

svg.app-logo {
  fill: #eb3c96;
  height: 100%;
  width: auto;
}

.identifier {
  padding-bottom: 12px;
}

table {
  table-layout: fixed;
}

table thead {
  background-color: #084056;
}

table th,
table td {
  padding: 8px 16px 4px;
}

table thead th {
  color: white !important;
  text-align: center !important;
}

table th,
table td,
table th:last-child,
table td:last-child {
  border: 1px solid #eee !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #084056;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: #084056 !important;
}

.css-1ygcj2i-MuiTableCell-root {
  width: 100%;
  overflow: hidden;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 5px !important;
  color: rgba(0, 0, 0, 0.87);
}

.tss-1cdcmys-MUIDataTable-responsiveBase {
  overflow: hidden !important;
}

.swal2-container {
  z-index: 9999 !important;
}

.tss-1fbujeu-MUIDataTableHeadCell-toolButton {
  padding-left: 130px !important;
}

.tss-bd2lw8-MUIDataTableHeadCell-sortActive {
  color: rgb(255 255 255) !important;
}

.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
  color: #eb3c96 !important;
}

.login-container {
  height: 30px;
}

th .MuiTableSortLabel-root.Mui-active {
  color: #fff !important;
}

.button-text {
  text-transform: capitalize !important;
}